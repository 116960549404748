import React from 'react';

import { parseHTML } from './../../helpers/parseHTML';
// @ts-ignore
import ArrowWhiteRight from '../../svgs/arrow-white-right.svg';
// @ts-ignore
import SearchMagnify from '../../svgs/search-magnify.svg';

interface props {
  data: any;
  searchTerm: string;
  setSearchTerm: Function;
  setSearchTermActive: Function;
}

interface state {
  placeholder: string;
}

export default class searchpageHeader extends React.Component<props, state> {
  searchInput: HTMLInputElement;
  constructor(props) {
    super(props);
    this.state = {
      placeholder: this.props.data.searchpage.searchInputPlaceholder,
    };
  }

  componentDidMount = () => {
    this.windowResizePlaceholder();
  };

  windowResizePlaceholder = () => {
    if (window.innerWidth < 768) {
      this.setState({
        placeholder: 'Search',
      });
    } else {
      this.setState({
        placeholder: this.props.data.searchpage.searchInputPlaceholder,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.searchTerm !== '') {
      this.props.setSearchTermActive(false);
      setTimeout(() => {
        this.props.setSearchTerm(this.searchInput.value);
        setTimeout(() => {
          this.props.setSearchTermActive(true);
        }, 100);
        this.searchInput.value = '';
      }, 400);
    } else {
      this.props.setSearchTerm(this.searchInput.value);
      setTimeout(() => {
        this.props.setSearchTermActive(true);
      }, 300);
      this.searchInput.value = '';
    }
  };

  render() {
    let data = this.props.data;

    return (
      <div className={`search-page__header hero-container`}>
        <h1 className={`search-page__header-title`}>
          {data.searchpage.searchTitle}
        </h1>
        <div className={`search-page__header-subtitle`}>
          {parseHTML(data.searchpage.searchSubtitle)}
        </div>
        <form
          className='search-page__header-search'
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <SearchMagnify className='search-page__header-search-icon' />
          <input
            type='text'
            className='search-page__header-search-input'
            // defaultValue={this.props.searchTerm !== '' ? this.props.searchTerm : null}
            placeholder={this.state.placeholder}
            ref={(searchInput) => (this.searchInput = searchInput)}
          />
       
            <button className='search-page__header-search-submit'>
              Search <ArrowWhiteRight />
            </button>
       
        </form>
      </div>
    );
  }
}
