import React from 'react'

// @ts-ignore
import CancelIcon from '../../svgs/cancel-black.svg'

interface props {
  data: any
  setActiveSubjectFilter: Function
  setActiveLevelFilter: Function
  removeActiveSubjectFilter: Function
  removeActiveLevelFilter: Function
  availableLevels: [string]
  availableSubjects: [string]
  activeSubjectFilters: [string]
  activeLevelFilters: [string]
}

interface state {
}

export default class searchpageFilters extends React.Component<props, state> {
  searchInput: HTMLInputElement;

  constructor(props) {
    super(props);
    this.state = {
    };
   
  }


  setFilter = (e, filter, type) => {
    
    switch (type) {
      case 'subject':
        if (this.props.activeSubjectFilters.includes(filter)) {
          // Remove filter if already active
          this.props.removeActiveSubjectFilter(filter);
        } else {
          // Add filter
          this.props.setActiveSubjectFilter(filter);
        }
        break;
      case 'level':
        if (this.props.activeLevelFilters.includes(filter)) {
          // Remove filter if already active
          this.props.removeActiveLevelFilter(filter);
        } else {
          // Add filter
          this.props.setActiveLevelFilter(filter);
        }
        break;
    
      default:
        break;
    }
    
    
  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    const searchpageFilters = JSON.parse(data.searchpage.searchLevelFilters);
  
    return (
      <div className={`search-page__filters middle-container`}>
        <div className="search-page__filters-subjects">
          <div className="search-page__filters-wrap">
            <span>Filter by:</span>

            {this.props.availableSubjects.map((subject, index) => {
              let active = this.props.activeSubjectFilters.includes(subject);
              return (
                <button 
                  className={`search-page__filters-subject available ${active && 'active'}`} 
                  key={`level-${index}`}
                  onClick={e => this.setFilter(e, subject, 'subject')}
                >
                    {subject}<CancelIcon/>
                </button>
              )
            })}
          </div>
        </div>
        <div className="search-page__filters-levels">
          {searchpageFilters.levels.map((level, index) => {
            let available = this.props.availableLevels.includes(level);
            let active = this.props.activeLevelFilters.includes(level);
            return (
              <button 
                className={`search-page__filters-level ${available && 'available'}  ${active && 'active'}`} 
                key={`level-${index}`}
                onClick={e => this.setFilter(e, level, 'level')}
                tabIndex={available || active ? null : -1}
              >
                {level}<CancelIcon/>
              </button>
            )
          })}
        </div>
        <div className="divider"></div>
      </div>
    )
  }
}
