import React from 'react'
import Layout from '../components/layouts/layout'
import { graphql, navigate } from 'gatsby'
import SEO from '../components/layouts/seo';
import { connect } from 'react-redux';
import SearchPageRibbons from '../components/searchpage/searchpage-ribbons';
import SearchPageHeader from './../components/searchpage/searchpage-header';
import SearchPageFilters from './../components/searchpage/searchpage-filters';
import SearchPageResults from './../components/searchpage/searchpage-results';
import { 
  clearAllSearch,
  setSearchTerm,
  setAvailableLevels,
  setAvailableSubjects,
  setNumberOfResults,
  setSearchTermActive,
  setActiveSubjectFilter,
  setActiveLevelFilter,
  removeActiveSubjectFilter,
  removeActiveLevelFilter 
} from './../state/actions';

interface props {
  data: any
  entry: any
  browser: string
  clearAllSearch: Function
  setAvailableLevels: Function
  setAvailableSubjects: Function
  setSearchTerm: Function
  setSearchTermActive: Function
  setNumberOfResults: Function
  setActiveSubjectFilter: Function
  setActiveLevelFilter: Function
  removeActiveSubjectFilter: Function
  removeActiveLevelFilter: Function
  activeSubjectFilters: [string]
  activeLevelFilters: [string]
  availableLevels: [string]
  availableSubjects: [string]
  searchTerm: string
  transitionStatus: string
  numberOfResults: number
  searchTermActive: boolean
}

interface state {
  searchQuery: string
}

class LearningSequencesSearch extends React.Component<props, state> {

  constructor(props) {
    super(props);
    this.state = {
      searchQuery: ''
    };

  }

  componentDidMount = () => {
    this.checkForSearch();
    this.setAvailableFilters();
  }

  componentWillUnmount = () => {
    this.props.clearAllSearch();
  }

  checkForSearch = () => {
    let { entry } = this.props;

    if (entry.state.hasOwnProperty('subject')) {
      this.props.setActiveSubjectFilter(entry.state.subject);
    }
  }

  setAvailableFilters = () => {
    let sequences = this.props.data.sequences.edges;
    let availableLevels = [];
    let availableSubjects = [];

    // Grab levels from current learning sequences
    for (let i = 0; i < sequences.length; i++) {
      const node = sequences[i].node;
      const levels = JSON.parse(node.levels);
      const subjects = JSON.parse(node.subjects);
      
      if (levels !== null) {
        for (let z = 0; z < levels.length; z++) {
          const level = levels[z];
          availableLevels.push(level);

          if (availableLevels.indexOf(level) === -1 && level !== undefined) {
            availableLevels.push(level);
          }
        }
      }

      if (subjects !== null) {
        for (let z = 0; z < levels.length; z++) {
          const subject = subjects[z];

          if (availableSubjects.indexOf(subject) === -1 && subject !== undefined) {
            availableSubjects.push(subject);
          }
        }
      }
    }
    
    availableSubjects.sort();

    this.props.setAvailableLevels(availableLevels);
    this.props.setAvailableSubjects(availableSubjects);
  }

  render () {
    let data = this.props.data;
    let {transitionStatus} = this.props;
    return (
      <>
        <SEO
          title={`Learning areas`} 
          description={data.searchpage.searchDescription} 
        />
        <Layout 
          data={data}
          class={`searchpage`}
          pageTitle={`Learning areas`}
          transitionStatus={transitionStatus}
        >
        <SearchPageRibbons
          data={data}
          browser={this.props.browser}
        />
        <SearchPageHeader
          data={data}
          searchTerm={this.props.searchTerm}
          setSearchTerm={this.props.setSearchTerm}
          setSearchTermActive={this.props.setSearchTermActive}
        />
        <SearchPageFilters
          data={data}
          availableLevels={this.props.availableLevels}
          availableSubjects={this.props.availableSubjects}
          setActiveSubjectFilter={this.props.setActiveSubjectFilter}
          setActiveLevelFilter={this.props.setActiveLevelFilter}
          removeActiveSubjectFilter={this.props.removeActiveSubjectFilter}
          removeActiveLevelFilter={this.props.removeActiveLevelFilter}
          activeSubjectFilters={this.props.activeSubjectFilters}
          activeLevelFilters={this.props.activeLevelFilters}
        />
        <SearchPageResults
          data={data}
          searchTerm={this.props.searchTerm}
          activeSubjectFilters={this.props.activeSubjectFilters}
          activeLevelFilters={this.props.activeLevelFilters}
          setSearchTerm={this.props.setSearchTerm}
          setSearchTermActive={this.props.setSearchTermActive}
          searchTermActive={this.props.searchTermActive}
          numberOfResults={this.props.numberOfResults}
          setNumberOfResults={this.props.setNumberOfResults}
        />
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    site: datoCmsSite {
      ...siteData
    }
    nav: datoCmsNavigation {
      ...navData
    }
    footer: datoCmsFooter {
      ...footerData
    }
    searchpage: datoCmsSearch {
      searchDescription
      searchInputPlaceholder
      searchLevelFilters
      searchSubjectFilters
      searchSubtitle
      searchTitle
    }
    sequences: allDatoCmsSequence {
      edges {
        node {
          levels
          primaryLevel
          subjects
          title
          slug
          searchTags
          sequenceListingDotPoints
          stages {
            stageTitle
            stageRelativeUrl
          }
          sequenceImage {
            alt
            fluid(maxHeight: 500) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`


export default connect(
  state => ({ 
    browser: state.browser,
    activeSubjectFilters: state.subjectFilters,
    activeLevelFilters: state.levelFilters,
    availableLevels: state.availableLevels,
    availableSubjects: state.availableSubjects,
    searchTerm: state.searchTerm,
    numberOfResults: state.numberOfResults,
    searchTermActive: state.searchTermActive,
    clearAllSearch: state.clearAllSearch
  }),
  dispatch => ({ 
    clearAllSearch: () => dispatch(clearAllSearch()),
    setActiveSubjectFilter: filter => dispatch(setActiveSubjectFilter(filter)),
    setActiveLevelFilter: filter => dispatch(setActiveLevelFilter(filter)),
    removeActiveSubjectFilter: filter => dispatch(removeActiveSubjectFilter(filter)),
    removeActiveLevelFilter: filter => dispatch(removeActiveLevelFilter(filter)),
    setSearchTerm: term => dispatch(setSearchTerm(term)),
    setSearchTermActive: term => dispatch(setSearchTermActive(term)),
    setAvailableLevels: availableLevels => dispatch(setAvailableLevels(availableLevels)),
    setAvailableSubjects: availableSubjects => dispatch(setAvailableSubjects(availableSubjects)),
    setNumberOfResults: numberOfResults => dispatch(setNumberOfResults(numberOfResults)),
  }),
)(LearningSequencesSearch);