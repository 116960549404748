import React from 'react'
import Fuse from 'fuse.js'
import Masonry from 'react-masonry-css'
import SearchpageListing from './searchpage-listing';
// @ts-ignore
import CancelIcon from '../../svgs/cancel-black.svg'

interface props {
  data: any
  numberOfResults: number
  searchTerm: string
  activeSubjectFilters: [string]
  activeLevelFilters: [string]
  setSearchTerm: Function
  setSearchTermActive: Function
  setNumberOfResults: Function
  searchTermActive: Boolean
}

interface state {
  sequences: any[]
  finalSequences: any[]
  showNoResultsText: Boolean
}

export default class SearchpageResults extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
      sequences: [],
      finalSequences: [],
      showNoResultsText: false
    };

  }

  componentDidMount = () => {
    this.checkSearchTerm()
    this.cleanSequencesData()
  }

  componentDidUpdate = (prevProps) => {    
    if (
      prevProps.activeSubjectFilters !== this.props.activeSubjectFilters ||
      prevProps.activeLevelFilters !== this.props.activeLevelFilters ||
      prevProps.searchTerm !== this.props.searchTerm
    ) {
      this.runSearch();
    } 
  }

  checkSearchTerm = () => {
    if (this.props.searchTerm !== '') {
      setTimeout(() => this.props.setSearchTermActive(true), 300);
    }
  }

  cleanSequencesData = () => {
    let rawSequences = this.props.data.sequences.edges;
    let sequenceArray = [];

    for (let i = 0; i < rawSequences.length; i++) {
      const node = rawSequences[i].node;
      sequenceArray.push(node)
    }

    this.setState({sequences: sequenceArray}, () => this.runSearch())
  }

  runSearch = async() => {
    await this.fadeOutSequences();
    let finalSequences = [];
    if (this.props.searchTerm !== '') {
      // Do Fuzzy search of search term
      let fuseOptions = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 500,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "title",
          "searchTags"
        ]
      };
      let fuse = new Fuse(this.state.sequences, fuseOptions); // "list" is the item array
      let fuseResults = fuse.search(this.props.searchTerm);
    
      // Check filters after search response
      finalSequences = this.checkFilters(fuseResults);
    } else {
      finalSequences = this.checkFilters(this.state.sequences);
    }

    this.props.setNumberOfResults(finalSequences.length)

    this.setState({finalSequences: finalSequences}, () => {
      this.fadeInSequences();
    })
  }

  fadeInSequences = () => {
    let finalSequencesCount = this.state.finalSequences.length;
    // Stagger reveal sequences
    for (let i = 0; i < finalSequencesCount; i++) {
      setTimeout( function timer(){
        let sequenceEl = document.querySelector('.sequence-listing-' + i);
        if (sequenceEl !== null) sequenceEl.classList.add('active')
      }, i*100 );
    }
  }

  fadeOutSequences = () => {
    return new Promise((resolve) => { 
    // Stagger hide sequences
      let fadedEls = 0;
      let finalSequencesCount = this.state.finalSequences.length;
      if (finalSequencesCount > 0) {
        for (let i = 0; i < finalSequencesCount; i++) {
          setTimeout( function timer(){
            let sequenceEl = document.querySelector('.sequence-listing-' + i);
            if (sequenceEl !== null) sequenceEl.classList.remove('active')
            fadedEls++
            if (fadedEls == finalSequencesCount) {
              setTimeout(() => resolve(), 300);
            }
          }, i*100);
        }
      } else {
        resolve();
      }
    });
  }

  checkFilters = (sequences) => {
    let activeSubjectFilters = this.props.activeSubjectFilters;
    let activeLevelFilters = this.props.activeLevelFilters;
    let finalSequences = [];

    if (activeSubjectFilters.length > 0 || activeLevelFilters.length > 0) {
      finalSequences = sequences.filter((sequence) => {
        // Must pass both filters
        if (activeSubjectFilters.length > 0 && activeLevelFilters.length > 0) {
          if (this.multipleFilterMatch(activeSubjectFilters, sequence.subjects) && this.multipleFilterMatch(activeLevelFilters, sequence.levels)) {
            return sequence
          }
        // Must pass only subject
        } else if (activeSubjectFilters.length > 0) {
          if (this.multipleFilterMatch(activeSubjectFilters, sequence.subjects)) {
            return sequence
          }
        // Must pass only level
        } else if (activeLevelFilters.length > 0) {
          if (this.multipleFilterMatch(activeLevelFilters, sequence.levels)) {
            return sequence
          }
        }
      })
      this.checkForNoResults(finalSequences);
      return finalSequences
    } else {
      finalSequences = sequences;
      this.checkForNoResults(finalSequences);
      return finalSequences
    }
  }

  checkForNoResults = (resultsArray) => {    
    if (resultsArray.length > 0) {
      this.setState({showNoResultsText: false})
    } else {
      this.setState({showNoResultsText: true})
    }
  }

  multipleFilterMatch = (filters, sequenceFilters) => {
    if (sequenceFilters !== null) {
      return filters.some(filter => sequenceFilters.includes(filter));
    } else {
      return false
    }
  }

  removeSearchTerm = () => {
    this.props.setSearchTermActive(false)
    setTimeout(() => {
      this.props.setSearchTerm('');
      this.runSearch();
    }, 300);
  }

  render () {
    let data = this.props.data;
    const breakpointColumnsObj = {
      default: 2,
      1202: 1,
    };
  
    return (
      <div className={`search-page__results middle-container`}>
        <span className="search-page__results-number">All resources ({this.props.numberOfResults})</span>
        {this.props.searchTerm !== '' ?
          <button 
            className={`search-page__results-term available ${this.props.searchTermActive ? 'active' : ''}`}
            onClick={this.removeSearchTerm}
          >
            Search term: <strong>{this.props.searchTerm}</strong><CancelIcon/>
          </button>
        :''}
          <p className={`search-page__results-none ${this.state.showNoResultsText ? 'active' : ''}`}>
            No results have been found <br/> Please try a new search or removing some filters above
          </p>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="search-page__results-container"
            columnClassName="search-page__results-container-column"
          >
            {this.state.finalSequences.map((sequence,index) => {
              return (
                <SearchpageListing
                  sequence={sequence}
                  index={index}
                  key={`sequence-${index}`}
                />
              )
            })}
        </Masonry>
      </div>
    )
  }
}
