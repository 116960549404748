import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

import AnimatedLink from './../global/animatedLink';
import { parseHTML } from './../../helpers/parseHTML';
// @ts-ignore
import ArrowRightGrey from '../../svgs/arrow-right-grey.svg';
// @ts-ignore
import ArrowRightDarkRed from '../../svgs/arrow-right-dark-red.svg';
// @ts-ignore
import ChevronBlackDown from '../../svgs/chevron-black-down.svg';
import { Link } from 'gatsby';

interface props {
  sequence: any;
  index: number;
}

interface state {
  stagesRevealed: Boolean;
}

export default class SearchpageListing extends React.Component<props, state> {
  footer: HTMLElement;

  constructor(props) {
    super(props);
    this.state = {
      stagesRevealed: false,
    };
  }

  componentDidMount = () => {};

  stagesReveal = () => {
    let buttonHeight = 65;
    let elHeight = this.footer.scrollHeight;

    if (this.footer.classList.contains('active')) {
      this.footer.style.maxHeight = buttonHeight + 'px';
      this.footer.classList.remove('active');
      this.setState({ stagesRevealed: false });
    } else {
      this.footer.style.maxHeight = elHeight + 'px';
      this.footer.classList.add('active');
      this.setState({ stagesRevealed: true });
    }
  };

  navigateToStage = (relativeUrl) => {
    let sequenceSlug = this.props.sequence.slug;
    let stageUrl = sequenceSlug + '/stages/' + relativeUrl;
    return stageUrl;
  };

  navigateToSequence = () => {
    let sequenceSlug = this.props.sequence.slug;
    return sequenceSlug;
  };

  render() {
    let sequence = this.props.sequence;
    
    let levels = JSON.parse(sequence.levels);
    let primaryLevel = JSON.parse(sequence.primaryLevel);
    let subjects = JSON.parse(sequence.subjects);
    return (
      <div
        className={`sequence-listing ${'sequence-listing-' + this.props.index}`}
      >
        <div className='sequence-listing__header'>
          <div className='sequence-listing__header-left'>
            <Img
              fluid={sequence.sequenceImage.fluid}
              alt={sequence.sequenceImage.alt}
            />
          </div>
          <div className='sequence-listing__header-right'>
            <div className='sequence-listing__header-info'>
              <p className='sequence-listing__header-subject'>
                {sequence.subjects !== null
                  ? subjects.map((subject, i) => {
                      if (i < subjects.length - 1 ) {
                        return subject + ' / '
                      } else {
                        return subject
                      }
                    })
                  : ''}
              </p>
              <p className='sequence-listing__header-level'>
                {levels !== null
                  ? levels.map((level, i) => {
                      if (primaryLevel && primaryLevel[0] === level) {
                        return (
                          <span className='primary' key={i}>
                            {level}
                          </span>
                        );
                      }
                      return <span key={i}>{level}</span>;
                    })
                  : ''}
              </p>
            </div>
            <AnimatedLink
              to={'/' + this.navigateToSequence()}
              className='sequence-listing__header-button'
            >
              Explore further <ArrowRightGrey />
            </AnimatedLink>
          </div>
        </div>
        <div className='sequence-listing__body'>
          <Link
            to={'/' + this.navigateToSequence()}
            className='sequence-listing__title'
          >
            <h1>{sequence.title}</h1>
          </Link>
          {parseHTML(sequence.sequenceListingDotPoints)}
        </div>
        {sequence.stages.length > 0 ? (
          <div
            className={`sequence-listing__footer`}
            ref={(ref) => (this.footer = ref)}
          >
            <button
              className='sequence-listing__footer-button'
              onClick={this.stagesReveal}
              aria-expanded={this.state.stagesRevealed ? 'true' : 'false'}
              aria-controls={`stages${this.props.index}`}
              id={`stages${this.props.index}-button`}
            >
              <ChevronBlackDown
                className={`sequence-listing__footer-chevron ${
                  this.state.stagesRevealed ? 'active' : ''
                }`}
              />
              <span>{sequence.stages.length} Stages</span>
            </button>
            <div className='sequence-listing__footer-stages-padding'>
              <div
                className={`sequence-listing__footer-stages`}
                id={`stages${this.props.index}`}
                role='region'
                aria-labelledby={`stages${this.props.index}-button`}
              >
                {sequence.stages.map((stage, index) => {
                  return (
                    <AnimatedLink
                      to={'/' + this.navigateToStage(stage.stageRelativeUrl)}
                      className='sequence-listing__stage-button'
                      key={`stage-${index}`}
                    >
                      <ArrowRightDarkRed />
                      {stage.stageTitle}
                    </AnimatedLink>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
